import React from "react"
import Link from './link'
import {Col} from 'reactstrap'
import Img from 'gatsby-image'

export default ({post}) => (
  <Col sm="4">
    <Link to={post.node.path} className="reset">
      {post.node.featured_media.localFile != null ?
        <Img fixed={post.node.featured_media.localFile.childImageSharp.fixed} style={{maxWidth: '100%', width: '100%'}}/> :
        <img src={post.node.featured_media.source_url} style={{maxWidth: '100%', width: '100%'}}/>
      }
      <p className="pt-2 font-weight-bold" dangerouslySetInnerHTML={{ __html: post.node.title}}/>
      <p className="text-secondary small" dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
      <div className="btn btn-secondary lighter mb-5">Read More</div>
    </Link>
  </Col>
)
